/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_MONIT } from "../../config";

import Monit from "../../components/Infomation";
import Footer from "../../components/footter";
import Header from "../../components/Header";

import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import axios from "axios";
import "./monitor.css";

const Monitor = () => {
  const [dataAntrean, setDataAntrean] = useState([]);
  const [dataSelesai, setDataSelesai] = useState([]);
  const [dataProses, setDataProses] = useState([]);

  const [namaSamsat, setNamaSamsat] = useState("");
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);

  const containerRef1 = useRef(null);
  const containerRef2 = useRef(null);
  const containerRef = useRef(null);

  const tkn = localStorage.getItem("token");

  const navigate = useNavigate();

  const checkToken = (id) => {
    if (tkn) {
      const decoded = jwtDecode(tkn);
      const currentDate = new Date();
      if (decoded.exp * 1000 < currentDate.getTime()) {
        navigate("/login");
      } else {
        setNamaSamsat(decoded.namaSamsat);
      }
    } else {
      navigate("/login");
    }
  };

  const getDataAntrean = async () => {
    try {
      const response = await axios.get(
        `${API_MONIT}/monitoring/order?statusKonfirm=1&statusShare=1`,
        {
          headers: {
            Authorization: `Bearer ${tkn}`,
          },
        }
      );
      setDataAntrean(response.data);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const getDataProses = async () => {
    try {
      const response = await axios.get(
        `${API_MONIT}/monitoring/order?statusKonfirm=1&statusShare=2`,
        {
          headers: {
            Authorization: `Bearer ${tkn}`,
          },
        }
      );
      setDataProses(response.data);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const getDataSelesai = async () => {
    try {
      const response = await axios.get(
        `${API_MONIT}/monitoring/order?statusKonfirm=2&statusShare=2`,
        {
          headers: {
            Authorization: `Bearer ${tkn}`,
          },
        }
      );
      setDataSelesai(response.data);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const handleLogOut = () => {
    Swal.fire({
      title: "Logout?",
      text: "Are you Sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Log Out!",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        sessionStorage.clear();
        const cookies = document.cookie.split("; ");

        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i];
          const eqPos = cookie.indexOf("=");
          const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
          document.cookie =
            name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
        }
        Swal.fire({
          icon: "success",
          title: "Success Log Out",
          showConfirmButton: false,
          timer: 1500,
        });
        navigate("/login");
      }
    });
  };

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (containerRef.current) {
        containerRef.current.scrollBy({
          top: containerRef.current.clientHeight,
          behavior: "smooth",
        });

        if (
          containerRef.current.scrollTop + containerRef.current.clientHeight >=
          containerRef.current.scrollHeight
        ) {
          containerRef.current.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      }
      getDataAntrean();
    }, 5000);

    return () => clearInterval(scrollInterval);
  }, []);

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (containerRef1.current) {
        containerRef1.current.scrollBy({
          top: containerRef1.current.clientHeight,
          behavior: "smooth",
        });

        if (
          containerRef1.current.scrollTop +
            containerRef1.current.clientHeight >=
          containerRef1.current.scrollHeight
        ) {
          containerRef1.current.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      }
      getDataProses();
    }, 5000);

    return () => clearInterval(scrollInterval);
  }, []);

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (containerRef2.current) {
        containerRef2.current.scrollBy({
          top: containerRef2.current.clientHeight,
          behavior: "smooth",
        });

        if (
          containerRef2.current.scrollTop +
            containerRef2.current.clientHeight >=
          containerRef2.current.scrollHeight
        ) {
          containerRef2.current.scrollTo({
            top: 0,
            behavior: "smooth",
          });
        }
      }
      getDataSelesai();
    }, 5000);

    return () => clearInterval(scrollInterval);
  }, []);

  useEffect(() => {
    getDataAntrean();
    getDataProses();
    getDataSelesai();
  }, [tkn]);

  useEffect(() => {
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // fungsi zoom layar
  const handleZoomIn = () => {
    const newZoom = zoomLevel + 0.1;
    document.body.style.zoom = newZoom;
    setZoomLevel(newZoom);
  };

  const handleZoomOut = () => {
    const newZoom = zoomLevel - 0.1;
    document.body.style.zoom = newZoom;
    setZoomLevel(newZoom);
  };

  // fullscreen
  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  return (
    <>
      {/* update tv ukuran=1280x720(dizoom 3 kali normal) */}
      {/* <div className="relative overflow-hidden bg-gray-700 pb-[170px]"> */}
      <div className="relative overflow-hidden bg-gray-700 pb-[150px] xl:pb-[150px] 2xl:pb-[170px]">
        <Header
          handleFullscreen={handleFullscreen}
          handleZoomIn={handleZoomIn}
          handleZoomOut={handleZoomOut}
          namaSamsat={namaSamsat}
        />
        <div className="text-center pt-5">
          <div className="text-center pt-5">
            <h2 className="text-white font-poppins-bold lg:text-xl">
              INFORMASI PROSES CETAK TNKB
            </h2>
          </div>
          <Monit
            dataAntrean={dataAntrean}
            dataProses={dataProses}
            dataSelesai={dataSelesai}
            animasi={containerRef}
            animasi1={containerRef1}
            animasi2={containerRef2}
          />
        </div>
        <Footer handleLogOut={handleLogOut} />
      </div>
    </>
    // </div>
  );
};

export default Monitor;
