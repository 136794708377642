import Monitor from "../pages/Monitor";
import Login from "../pages/Login";

export const Paths = [
  {
    path: "/",
    element: <Monitor />,
    // element: <Root />,
    // errorElement: <ErrorPage />,
    // children: [
    //   {
    //     path: "",
    //     element: <Monitor />,
    //   },
    // ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/monitor",
    element: <Monitor />,
  },
];
