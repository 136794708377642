import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { API_LOGIN } from "../../config";
import { useState } from "react";

import InputPassword from "../../components/Form/InputPassword";
import WarningAlert from "../../components/Alert/WarningAlert";
import logoLogin from "../../assets/images/oct.png";
import InputText from "../../components/Form/InputText";

import axios from "axios";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [isAlert, setIsAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPassword, setIsPassword] = useState(false);

  const navigate = useNavigate();

  const Auth = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${API_LOGIN}/monit/login`, {
        username: username,
        password: password,
        // tokenFcm: '99898989'
      });

      const token = response.data.token;
      localStorage.setItem("token", token);

      navigate("/monitor");
    } catch (error) {
      if (error.response) {
        setErrorMessage(error.response.data.message);
        setIsAlert(true);
      }
    }
  };

  const handlePassword = () => {
    if (isPassword) {
      setIsPassword(false);
    } else {
      setIsPassword(true);
    }
  };

  return (
    <section className="bg-gray-700">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-stone-100 rounded-lg drop-shadow-xl md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-2 md:space-y-3 sm:p-8">
            <div className="flex flex-col items-center">
              <img className="h-36" src={logoLogin} alt="Logo Gloprint" />
            </div>
            {isAlert && (
              <>
                <WarningAlert description={errorMessage} />
              </>
            )}

            <form onSubmit={Auth}>
              <div className="grid gap-3 mb-6 grid-cols-6 mt-4">
                <div className="col-start-1 md:col-span-6 col-span-6">
                  <InputText
                    label="Username"
                    name="username"
                    set={setUsername}
                    val={username}
                  />
                </div>
                <div className="col-start-1 md:col-span-6 col-span-6 relative">
                  <InputPassword
                    type={isPassword ? "text" : "password"}
                    label="Password"
                    name="password"
                    set={setPassword}
                    val={password}
                  />
                  <span
                    className="absolute bottom-3 end-3"
                    onClick={() => handlePassword()}
                  >
                    {isPassword ? <FaRegEye /> : <FaRegEyeSlash />}
                  </span>
                </div>
              </div>
              <button
                type="submit"
                className="w-full text-white bg-gray-700 hover:bg-gray-700/90 focus:ring-4 focus:outline-none focus:ring-primary-300 font-sans font-bold rounded-lg text-sm px-5 py-3 text-center shadow-lg"
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
