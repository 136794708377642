import oct from "../../assets/images/oct-white.png";
import logo2 from "../../assets/images/Logo1.png";
import logo1 from "../../assets/images/logo.png";
import { RiFullscreenExitFill } from "react-icons/ri";
import { GrZoomIn, GrZoomOut } from "react-icons/gr";

const Header = ({
  namaSamsat,
  handleFullscreen,
  handleZoomIn,
  handleZoomOut,
}) => {
  return (
    <div className="navbar flex justify-between items-center z-20 h-14 w-full pl-20 pr-20 rounded-none bg-gray-700 shadow-2xl gap-1 md:gap-3">
      <span className="font-poppins-bold text-4xl text-white text-center">
        <span className="flex gap-1 justify-center items-center ">
          <img src={oct} className="w-[45px] h-[45px]" alt="" />
          <div className="flex flex-col gap-0 justify-start items-start">
            <span className="font-poppins-bold text-2xl text-red-600">
              OCT SYSTEM
            </span>
            <span className="font-poppins-semibold text-sm text-white -mt-2">
              {namaSamsat}
            </span>
          </div>
        </span>
      </span>
      <div className="flex gap-10">
        {/* full zoom in */}
        <button onClick={handleZoomIn} id="button" className="text-white">
          <GrZoomIn size={20} />
        </button>
        {/* full screen */}
        <button onClick={handleFullscreen} id="button" className="text-white">
          <RiFullscreenExitFill size={20} />
        </button>
        {/* full zoom out */}
        <button onClick={handleZoomOut} id="button" className="text-white">
          <GrZoomOut size={20} />
        </button>
      </div>
      <span className="flex gap-3  font-poppins-bold text-4xl text-white text-center">
        <img src={logo1} className="w-[45px] h-[45px]" alt="" />
        <img src={logo2} className="w-[45px] h-[45px]" alt="" />
      </span>
    </div>
  );
};

export default Header;
