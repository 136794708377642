import { AiOutlineLogout } from "react-icons/ai";
import { useEffect, useState } from "react";
import { RiFullscreenExitFill } from "react-icons/ri";

const Footer = ({ handleLogOut }) => {
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    const updateCurrentTime = () => {
      const now = new Date();
      const options = {
        year: "numeric",
        month: "long",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
        timeZoneName: "short",
      };
      const formattedTime = new Intl.DateTimeFormat("id-ID", options)
        .format(now)
        .replace(" GMT+7", " WIB");
      setCurrentTime(formattedTime);
    };

    updateCurrentTime();
    const timeInterval = setInterval(updateCurrentTime, 1000);

    return () => clearInterval(timeInterval);
  }, []);

  // function full screen
  const toggleFullScreen = () => {
    const element = document.getElementById("buttonContainer");
    const isFullScreen = document.fullscreenElement;

    console.log(isFullScreen);
    if (isFullScreen) {
      document.exitFullscreen();
    } else {
      element.requestFullscreen();
    }
  };

  return (
    <>
      <div className="flex justify-between absolute items-center bottom-0 pr-20 h-10 bg-blue-500 w-full">
        <div className="flex gap-2 items-center w-full">
          <span className="font-semibold bg-red-500 text-xl capitalize pr-5 pl-5 z-10 text-white p-1">
            {currentTime}
          </span>
          <span className="marquee font-semibold text-xl text-white w-[30%] z-0">
            OCT System adalah sistem yang dikelola oleh SAMSAT untuk order cetak
            TNKB ● OCT System didukung oleh KORLANTAS POLRI ● Masyarakat dapat
            memonitor proses cetak TNKB ● CETAK STNK LANGSUNG MONITOR CETAK TNKB
          </span>
        </div>
        <div className="flex gap-2 items-center bg-blue-500 z-10 p-1 px-5">
          <button
            onClick={() => handleLogOut()}
            className="btn flex justify-center items-center gap-1 bg-red-500  text-white hover:bg-red-500/80 py-1 px-3 rounded-lg"
          >
            <AiOutlineLogout size={20} />
          </button>

          {/* full screen */}
          {/* <button onClick={toggleFullScreen} id="button">
          <RiFullscreenExitFill size={20} />
        </button> */}
        </div>
      </div>
    </>
  );
};

export default Footer;
