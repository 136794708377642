const InputPassword = ({ label, name, set, val, type }) => {
  return (
    <>
      <label
        htmlFor={name}
        className="block mb-2 text-sm font-sans text-gray-700 font-bold"
      >
        {label}
      </label>
      <input
        type={type}
        name={name}
        id={name}
        placeholder="••••••"
        value={val}
        onChange={(e) => set(e.target.value)}
        className="bg-gray-50 ring-1 border-0 ring-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 block w-full p-2 font-sans"
        required
      />
    </>
  );
};

export default InputPassword;
