import React from "react";

const InputText = ({ label, name, set, val }) => {
  return (
    <div>
      <label
        htmlFor={name}
        className="block mb-2 text-sm font-sans text-gray-700 font-bold"
      >
        {label}
      </label>
      <input
        type="text"
        name={name}
        id={name}
        value={val}
        onChange={(e) => set(e.target.value)}
        className="bg-gray-50 ring-1 border-0 ring-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 block w-full p-2 font-sans"
        placeholder="username"
        required
      />
    </div>
  );
};

export default InputText;
