import Antrean from "./DataAntrean";
import Selesai from "./DataSelesai";
import Proses from "./DataProses";
import React from "react";

const Monit = ({
  dataAntrean,
  dataProses,
  dataSelesai,
  animasi,
  animasi1,
  animasi2,
}) => {
  return (
    <div className="bg-gray-700 sm:flex pt-4 gap-1">
      <Antrean data={dataAntrean} containerRef={animasi} />
      <Proses data={dataProses} containerRef1={animasi1} />
      <Selesai data={dataSelesai} containerRef2={animasi2} />
    </div>
  );
};

export default Monit;
